<div class="mysites-div">
    <button #scrollBtn (click)="topFunction()" class="btn-nativ scroll-btn" title="Go to top"><i
            class="fas fa-chevron-up"></i></button>
    <div class="first-div">
        <div class="row">
            <div *ngIf="showAdvs" class="col-md-2 text-center right-adv ext-adv-div">
                <!-- <div class="col-md-12 text-center mx-auto">
                    <img class="adv-img" [src]="advsData[0].src" />
                </div> -->
                <iframe *ngIf="showAdvs" scrolling="no" class="" frameBorder="0" class="adv-iframe-side"
                    src="../../../assets/images/advs/ezer-mezion/ezer_mezion176x736.html"></iframe>
                <span style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 10;"
                    (click)="navigateToAdvSite(advsData[0].link,'right')"></span>
            </div>
            <div class="col-md-8 main-content">
                <div class="d-flex justify-content-center align-items-center text-center">
                    <div class="makeBorder">
                        <h5 class="sites-header" [title]="'my-sites.sub-header' | translate">{{ 'my-sites.header' |
                            translate }}
                        </h5>
                        <!--<h6 class="sites-sub-header">{{ 'my-sites.sub-header' | translate }}</h6>-->
                        <a *ngIf="!refreshing" class="btn-nativ" (click)="refreshNativ()" target="_blank">{{
                            'global.refresh' |
                            translate
                            }}</a>
                        <button *ngIf="refreshing" class="btn-nativ refreshing">
                            {{ 'global.refresh-now' | translate }}
                            <img src='../../../assets/images/new/nativ-gif-2.gif' />
                        </button>
                        <div *ngIf="!isCadenceUser" class="filter-div ">
                            <div class="radio-header text-center">{{ 'my-sites.my-sites-only-header' |translate }}</div>
                            <label>
                                <input type="checkbox" id="inlineCheckbox2" value="option1" name="my-sites"
                                    [(ngModel)]="isChecked1" (change)="mySitesOnly()">{{
                                'my-sites.my-sites-only-sub-header'
                                |translate }}
                            </label>
                        </div>
                        <!-- <div class="d-flex justify-content-center">
                    <div *ngIf="!noData" class="paging-div">
                        <div class="pagination-container">
                            <span class="pagination-label">{{ 'my-sites.page-num' | translate }}</span>
                            <input type="number" class="pagination-input" min="1" max="{{ collectionSize }}"
                                [(ngModel)]="page" (ngModelChange)="onPageChange(page)">
                            <span class="pagination-label"> {{ 'my-sites.page-num-continue' | translate }} {{
                                lastPage }} | </span>
                            <div class="request-count" *ngIf="!loading && !noData"><span> {{
                                    'my-sites.sites-found'|translate:{'num-sites':sitesLength } }}
                                    {{collectionSize}}</span>
                            </div>
                        </div>
                    </div>
                </div> -->
                    </div>
                </div>
                <div *ngIf="!noData" class="text-center initializeFilters" (click)="unfiltering()">{{
                    'my-sites.reset-filters' | translate }}
                </div>
                <table class="table table-striped text-center"
                    [ngClass]="{'regular': manager === false, 'mySiteExpanded': manager === true}">
                    <thead>
                        <tr>
                            <th scope="col" [ngClass]="lang === 'EN' ? 'dateEN' : 'date'">
                                <div class="d-flex align-items-center justify-content-center">
                                    <div class="arrows">
                                        <div (click)="sorting('date')">
                                            <i *ngIf="sorted !== 'date'&&sorted !== 'date desc'" class="fa fa-sort"
                                                aria-hidden="true"></i>
                                            <i *ngIf="sorted === 'date desc'" class="fa fa-sort-desc"
                                                aria-hidden="true"></i>
                                            <i *ngIf="sorted === 'date'" class="fa fa-sort-asc" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <ng-multiselect-dropdown [settings]="dropdownSettingsDate" [data]="dateArr"
                                        [(ngModel)]="selectedItemsDate"
                                        (onSelect)="onItemSelect('date',selectedItemsDate)"
                                        (onDeSelect)="onItemDeSelect('date',selectedItemsDate)" [placeholder]="' '">
                                    </ng-multiselect-dropdown>
                                </div>
                            </th>
                            <th scope="col" [ngClass]="lang === 'EN' ? 'urlEN' : 'url'">
                                <div class="d-flex align-items-center justify-content-center "
                                    [ngClass]="{'show-filter': nShowFilterMenu}">
                                    <div class="arrows">
                                        <div (click)="sorting('url')">
                                            <!-- <div>{{ 'my-sites.table.name' | translate }} -->
                                            <i *ngIf="sorted !== 'url'&&sorted !== 'url desc'" class="fa fa-sort"
                                                aria-hidden="true"></i>
                                            <i *ngIf="sorted === 'url desc'" class="fa fa-sort-desc"
                                                aria-hidden="true"></i>
                                            <i *ngIf="sorted === 'url'" class="fa fa-sort-asc" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <ng-multiselect-dropdown [settings]="dropdownSettingsUrl" [data]="sitesUrl"
                                        [(ngModel)]="selectedItemsUrl" (onFilterChange)="onFilterChange($event)"
                                        (onSelect)="onItemSelect('url',selectedItemsUrl)"
                                        (onDeSelect)="onItemDeSelect('url',selectedItemsUrl)" [placeholder]="' '"
                                        (onDropDownClose)="exitUrlSelect()">
                                    </ng-multiselect-dropdown>
                                </div>
                            </th>
                            <th scope="col" class="status my-sites" [ngClass]="lang === 'EN' ? 'statusEN' : 'status'">
                                <!-- <div class="d-flex align-items-center justify-content-center"> -->
                                <ng-multiselect-dropdown [settings]="dropdownSettingsStatus" [data]="statusArr"
                                    [(ngModel)]="selectedItemsStatus" (onSelect)="onItemSelectStatus()"
                                    (onDeSelect)="onItemDeSelectStatus()" [placeholder]="' '">
                                </ng-multiselect-dropdown>
                                <!-- </div> -->
                            </th>
                            <th scope="col" class="remove text-right">{{ 'my-sites.table.remove' | translate }}</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="!loadingAllTable">
                        <tr *ngFor="let site of sites; index as i" id="id_{{site.id}}"
                            [ngClass]="{'tr-border':  siteId!=null && siteId.toString() ==  site.siteid.toString()}">
                            <td class="d-none">{{site.siteid}}</td>
                            <td [attr.data-label]="'my-sites.table.date' | translate" class="date text-left">{{
                                site.date }}
                            </td>
                            <td [attr.data-label]="'my-sites.table.name' | translate" class="name"
                                title="{{ site.name }}">
                                {{site.sitename ? site.sitename : ""}} <br *ngIf='site.sitename' /><a *ngIf='site.isUrl'
                                    href="{{site.url.startsWith('http') ? site.url : 'http://'+site.url }}"
                                    target="_blank">{{
                                    site.url
                                    }}</a>{{ site.isUrl ? "" : site.url }}</td>
                            <td [attr.data-label]="'my-sites.table.status' | translate"
                                *ngIf='!site.status.includes("מנוע טיפול")' class="status">{{ site.status }}<br />
                                <a (click)="refreshNativ()" class="pointer"
                                    *ngIf='!refreshing && ((!site.status.includes("לא אושר") && !site.status.includes("במייל") && !site.status.includes("בהמתנה")) || (site.status.includes("אושר") && !site.status.includes("לא אושר")))'>
                                    {{ 'my-sites.table.to-refresh' |
                                    translate }}</a>
                                <a class="pointer refreshing"
                                    *ngIf='refreshing && ((!site.status.includes("לא אושר") && !site.status.includes("במייל") && !site.status.includes("בהמתנה")) || (site.status.includes("אושר") && !site.status.includes("לא אושר")))'>
                                    {{ 'my-sites.table.refreshing' |
                                    translate }}<img class="refresh-gif"
                                        src='../../../assets/images/new/nativ-gif-2.gif' /></a>
                            </td>
                            <td [attr.data-label]="'my-sites.table.status' | translate"
                                *ngIf='site.status.includes("מנוע טיפול")' class="status pointer">
                                <a (click)="addSite(site.url)">{{ site.status }}</a>
                            </td>
                            <td [attr.data-label]="'my-sites.table.remove' | translate" class="garbage">
                                <div type="button"
                                    [ngClass]="{'garbage1 btn btn-outline-secondary me-2':site.remove,'no-pointer': !site.remove}"
                                    placement="top"
                                    ngbTooltip='{{site.remove ? site.removeTitle.includes("בקשה") ? "למחיקת הבקשה" : "באם סיימתם את השימוש באתר ואינכם נצרכים לו- לחצו כאן להסרת האתר מהמאגר האישי שלכם" : ""}}'
                                    (click)="removeSite(site)">
                                    <ng-container *ngIf="site.remove">
                                        <img class="garbage-image" src="../../../assets/images/my-sites/garbage.png" />
                                    </ng-container>
                                    <ng-container *ngIf="!site.remove">
                                        ---
                                    </ng-container>
                                </div>
                            </td>
                            <td *ngIf='(site.remove || site.status.includes("טופל-קיים")) && displaySimActions'
                                class="name">
                                <button (click)="sync(site.siteid)" class="btn-nativ">{{
                                    'profile.table.btn-sync' | translate }}</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="loading || loadingAllTable" id="loading"
                    class="d-flex justify-content-center sites-loader table-sites">
                    <img src='../../../assets/images/new/nativ-gif-2.gif' />
                </div>
                <!-- <div *ngIf="!noData" class="d-flex justify-content-center p-4">
            <ngb-pagination (pageChange)="onPageChange($event)" [collectionSize]="collectionSize" [(page)]="page"
                [pageSize]="pageSize" (pageChange)="refreshSites()" [boundaryLinks]="false" [maxSize]="5">
            </ngb-pagination>
        </div> -->
            </div>
            <div *ngIf="showAdvs" class="col-md-2 text-center left-adv ext-adv-div">
                <!-- <div class="col-md-12 text-center mx-auto">
                    <img class="adv-img" [src]="advsData[1].src" />
                </div> -->
                <iframe *ngIf="showAdvs" scrolling="no" class="" frameBorder="0" class="adv-iframe-side"
                    src="../../../assets/images/advs/ezer-mezion/ezer_mezion176x736.html"></iframe>
                <span style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 10;"
                    (click)="navigateToAdvSite(advsData[1].link,'left')"></span>
            </div>
        </div>
        <div *ngIf="showAdvs && advsData[2].show" class="ext-adv-div floating-adv">
            <button type="button" class="close" (click)="closeAdv(2)" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <!-- <img [src]="advsData[2].src" (click)="navigateToAdvSite(advsData[2].link,'bottom')" /> -->
            <iframe *ngIf="showAdvs" scrolling="no" class="" frameBorder="0" class="adv-iframe-bottom"
                src="../../../assets/images/advs/ezer-mezion/ezer_mezion70x904.html"></iframe>
            <span style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 10;"
                (click)="navigateToAdvSite(advsData[2].link,'bottom')"></span>
        </div>
    </div>
    <app-bottom-nav></app-bottom-nav>
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../services/api/api.service';
import { Router } from "@angular/router";
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from '../../../environments/environment';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-site',
  templateUrl: './add-site.component.html',
  styleUrls: ['./add-site.component.scss'],
})
export class AddSiteComponent implements OnInit {
  @ViewChild('urlInput') urlInput: ElementRef;
  @ViewChild('usernameInput') usernameInput: ElementRef;
  @ViewChild('addSitePassInput') addSitePassInput: ElementRef;
  @ViewChild('fullnameInput') fullnameInput: ElementRef;
  @ViewChild('projectInput') projectInput: ElementRef;
  @ViewChild('passwordInput') passwordInput: ElementRef;
  @ViewChild('mailInput') mailInput: ElementRef;

  addSiteForm: any;
  submitted = false;
  showChangeUser = false;
  url = "";
  passRequired = false;
  validPass = true;
  displayUserName;
  isUserLoggedIn = false;
  displayQuota = "";
  overQuota = false;
  isChecked = false;
  enableSubmit = true;
  isThink = true;
  disabledUsername = true;
  displayEmailInput = true;
  gviaproblemAlert = "עקב בעיה בגביה הנך מנוע מלהוסיף אתרים, אנא פנה להנהלת חשבונות להמשך בירור";
  gviaproblem = false;
  displayNameInput = false;
  displayProjectInput = false;
  matrixUser = false;
  userData: any;
  displayEmailDB = false;
  emailDbAlert = "לקוח יקר,<br />תגובות מערכת נתיב על בקשות האתרים נשלחות ללקוח למייל,<br />כיון שבכרטיס המשתמש שלך במערכת לא קיימת כתובת מייל לא נוכל לשלוח אליך את התשובות.<br />אם ברשותך כתובת מייל נא ציין אותה בתיבה המודגשת בצהוב<br /></td>"
  sukotAlert = 'על פי הוראת הרבנים המלווים את חברת נתיב <br /> עקב קדושת המועד <br /> המענה בימי חול המועד סוכות יהיה במתכונת חירום <br />  <div class = "bold" > ומיועד לצורכי עבודה בלבד! <br/> </div>  יש לבדוק שבקשתך עונה על ההגדרה בשלמות <br /> כדי למנוע אי נעימות. <br /><br /> לתשומת לבך: אתר נתיב עומד לרשותך עם מגוון שירותים יעילים <br /> שיכולים לתת לך מענה בקלות ובמהירות.<br/><br/> בברכת מועדים לשמחה <br/> וחג שמח!'
  changedUser = false;
  reqDec = -1
  reqCats = ""
  reqSid = -1
  reqRef = ""
  userId: any = 0
  userDataAuth: any;
  reqIp = ""
  queryParams: any;
  details: any;
  userDataDetails: any;
  showAdvs = true;
  isIaiUser = false
  advsData = [
    { src: "../../../assets/images/advs/ezer-mezion/ezer_mezion176x736.html", show: true, link: "ezer-mitzion" },
    { src: "../../../assets/images/advs/ezer-mezion/ezer_mezion176x736.html", show: true, link: "ezer-mitzion" },
    { src: "../../../assets/images/advs/ezer-mezion/ezer_mezion70x904.html", show: false, link: "ezer-mitzion" }
  ]
  emailInput = ""
  isEltaServer = false
  isHe: boolean;
  isPasswordVisible = false;

  constructor(public translateService: TranslateService,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private router: Router,
    public authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private elementRef: ElementRef) {
    if (translateService.currentLang == "he") {
      this.isHe = true
    }
    this.route
      .queryParams
      .subscribe(params => {
        this.queryParams = params;
        if (params["arachim"] == 1) {
          this.router.navigate(['/add-site-arachim'], { queryParams: this.queryParams, skipLocationChange: true })
        }
        if (params["userid"]) {
          this.userId = params["userid"]
        }
      })
    this.addSiteForm = this.formBuilder.group({
      username: '',
      password: '',
      userPassword: '',
      url: '',
      comments: '',
      email: '',
      name: '',
      project: '',
      username1: '',
      url1: '',
      comments1: '',
      username2: '',
      url2: '',
      comments2: '',
    });
    this.authenticationService.authenticated$.subscribe(value => {
      this.isUserLoggedIn = value;
      this.userDataAuth = this.authenticationService.userData;
      this.initPage(this.userDataAuth);
    });
    // this.changeAdvsPlace()
  }
  changeAdvsPlace() {
    const now = new Date();
    const minute = now.getMinutes();
    if (minute % 3 === 0) {
      this.advsData = [
        { src: "../../../assets/images/advs/shtiglitz-group/735X176.png", show: true, link: "shtiglitz-group" },
        { src: "../../../assets/images/advs/shtiglitz-group/735X176.png", show: true, link: "shtiglitz-group" },
        { src: "../../../assets/images/advs/shtiglitz-group/70X904.png", show: false, link: "shtiglitz-group" }
      ]
    }
    else if (minute % 3 === 1) {
      this.advsData = [
        { src: "../../../assets/images/advs/shtiglitz-group/735X176.png", show: true, link: "shtiglitz-group" },
        { src: "../../../assets/images/advs/shtiglitz-group/735X176.png", show: true, link: "shtiglitz-group" },
        { src: "../../../assets/images/advs/shtiglitz-group/70X904.png", show: false, link: "shtiglitz-group" }
      ]
    }
  }
  initPage(userData) {
    if (this.isUserLoggedIn) {
      if (this.userDataAuth.lowVersion && this.userDataAuth.BlockActivity.includes("2")) {
        this.router.navigate(["/main"])
        Swal.fire("", "לקוח/ה יקר/ה \n\n במחשב זה מותקנת גירסה ישנה של נתיב, \n לא ניתן להוסיף אתרים בגירסה זו. \n נא ליצור קשר עם מוקד התמיכה לצורך שידרוג מיידי במספר 5365* או 036199199 ", 'warning')
      }
      try {
        this.apiService.getUserData(userData.username).subscribe(
          data => {
            this.checkShowAdvs()
            this.details = data;
            this.userData = this.details["userData"]["d"]
            const userData = this.userData
            const userType = userData.usertypeid.substring(5, 6)
            if (userType.indexOf("9") > -1 || userType.indexOf("A") > -1 || userType.indexOf("C") > -1 || userType.indexOf("7") > -1 || userType.indexOf("B") > -1) {
              this.router.navigate(['/main'])
            }
            if (userData.noaddsites == "1") {
              Swal.fire("", "על פי הגדרות המשתמש, אין אפשרות להוסיף אתרים לחשבונך. לבירורים פנה לשירות הלקוחות של נתיב", 'warning')
              this.router.navigate(['/main'])
            }
            // if (["9", "A", "C", "7", "B"].includes(userData.usertypeid.substring(5, 6)) || userData.noaddsites == "1") {
            //   this.router.navigate(['/main'])
            // }
            this.displayUserName = userData.username;
            this.disabledUsername = this.displayUserName ? true : false;
            if (userData.ReceiveMailMessage == "2") {
              this.displayEmailInput = false;
            }
            if (userData.usertypeid[userData.usertypeid.length - 1] == "M") {
              this.matrixUser = true;
            }
            if (userData.isOrgServer == 1 || this.isEltaServer) {
              this.addSiteForm.get('email').setValidators([Validators.required, Validators.email])
            }
            if (this.userDataAuth.id == environment.iaiServerUserId) {
              this.isIaiUser = true
            }
            else if (this.userDataAuth.id == environment.eltaServer) {
              this.isEltaServer = true
            }

            if (this.matrixUser) {
              this.addSiteForm.get('name').setValidators(Validators.required)
              this.addSiteForm.get('project').setValidators(Validators.required)
              this.addSiteForm.get('email').setValidators([Validators.required, Validators.email])
            }

            this.passRequired = userData.AddSitesCode != null && userData.AddSitesCode != "";

            if (this.passRequired) {
              this.addSiteForm.get('password').setValidators(Validators.required)
              this.addSiteForm.get('password').setErrors('invalid password')
            }
            if (userData.gviaproblem == "1") {
              this.enableSubmit = false;
              this.gviaproblem = true;
            }
            else {
              this.displayQuota = this.userData.quota < 1 ?
                "עברת את מכסת האתרים ללא תשלום לחודש זה" :
                "נותרו לך " + this.userData.quota + " אתרים להוספה ללא תשלום לחודש זה";

              if (this.userData.quota < 1) {
                if (userData.usertypeid.substring(5, 6) == "4") {
                  this.router.navigate(['/docs/change-path-doc'])
                }
                else {
                  this.displayQuota = "עברת את מכסת האתרים ללא תשלום לחודש זה";
                  this.overQuota = true;
                  this.enableSubmit = false;
                }
              }
              else {
                this.displayQuota = "נותרו לך " + this.userData.quota + " אתרים להוספה ללא תשלום לחודש זה";
              }

            }
          },
          error => {
            console.log('error getUserData', error)
          }
        )
      }
      catch (error) {
        console.log('error getUserData', error)
      }
    }
    else if (this.userId > 0) {
      if (this.userId == environment.iaiServerUserId || this.userId == environment.iaiServer1UserId
        || this.userId == environment.arachimL1UserId || this.userId == environment.arachimL2UserId
        || this.userId == environment.arachimL3UserId || this.userId == environment.arachimL4UserId
        || this.userId == environment.arachimL5UserId || this.userId == environment.arachimL6UserId
        || this.userId == environment.arachimL7UserId) {
        // (this.userId == environment.eltaServer && this.reqIp == environment.eltaServerIP)
        this.apiService.probDocLogin(this.userId).subscribe(
          data => {
            try {
              console.log('success probDocLogin', data)
              data = data["data"]
              if (data && data["UserData"]) {
                data["UserData"].username = data["UserData"].login
                this.authenticationService.authenticate(data["UserData"]);
                this.displayUserName = data["UserData"].username;
              }
            }
            catch (error) {
              console.log(error)
            }
          },
          error => {
            console.log('error probDocLogin', error)
          }
        )
      }
      else if (this.userId == environment.eltaServer) {
        this.apiService.getDataInInitAspxPage().subscribe(
          (response: any) => {
            console.log('Response:', response);
            const parts: string[] = response.split("#");
            const ip: string = parts[parts.length - 2];
            console.log("ip:", ip)
            if (ip == environment.eltaServerIP1 || ip == environment.eltaServerIP2) {
              this.apiService.probDocLogin(this.userId).subscribe(
                data => {
                  try {
                    console.log('success probDocLogin', data)
                    data = data["data"]
                    if (data && data["UserData"]) {
                      data["UserData"].username = data["UserData"].login
                      this.authenticationService.authenticate(data["UserData"]);
                      this.displayUserName = data["UserData"].username;
                    }
                  }
                  catch (error) {
                    console.log(error)
                  }
                },
                error => {
                  console.log('error probDocLogin', error)
                }
              )
            }
            else {
              Swal.fire("", "כתובת IP זה חסום להוספת אתר", 'error')
              this.router.navigate(['/main'])
            }
          },
          (error: any) => {
            Swal.fire("", "כתובת IP זה חסום להוספת אתר", 'error')
            this.router.navigate(['/main'])
            console.error('Error:', error);
          }
        );
      }
    }
    else {
      if (JSON.parse(localStorage.getItem('nativUser'))) {
        const storageData = JSON.parse(localStorage.getItem('nativUser'))
        if (storageData.UserID < 1) {
          this.router.navigate(["/enter"]);
        }
      }
      else
        this.router.navigate(["/enter"]);
    }
  }

  ngOnInit(): void {
    // Swal.fire("", this.sukotAlert, 'warning')

    this.openAdv(2)
    this.buildForm();
    this.disabledUsername = this.displayUserName ? true : false;

    this.route
      .queryParams
      .subscribe(params => {
        if (params["URL"] || params["url"]) {
          const url = params["URL"] ? params["URL"] : params["url"];
          const middle = url.length / 2
          const part1 = url.substring(0, middle)
          const part2 = url.substring(middle, url.length)
          if (part1 == part2) {
            this.url = part1;
          }
          else {
            this.url = url;
          }
        }
        if (params["userid"]) {
          this.userId = params["userid"]
        }
        if (params["iip"]) {
          this.reqIp = params["iip"]
        }
        if (params["dec"]) {
          this.reqDec = params["dec"]
        }
        if (params["cats"]) {
          this.reqCats = params["cats"]
        }
        if (params["sid"]) {
          this.reqSid = params["sid"]
        }
        if (params["ref"]) {
          this.reqRef = params["ref"]
        }
        if (params["email"]) {
          this.emailInput = params["email"]
        }
        if (params["netsparkuserid"]) {
          this.apiService.netsparkLogin(params["netsparkuserid"]).subscribe(
            data => {
              try {
                console.log('success netsparkLogin', data)
                if (data["data"]["UserData"]) {
                  data["data"]["UserData"].username = data["data"]["UserData"].login
                  this.authenticationService.authenticate(data["data"]["UserData"]);
                  this.displayUserName = this.authenticationService.userData.username;
                }
              }
              catch (error) {
                console.log(error)
              }
            },
            error => {
              console.log('error netsparkLogin', error)
            }
          )
        }
      })
  }

  ngAfterViewInit() {
    try {
      this.mailInput?.nativeElement.addEventListener('input', () => {
        const inputValue = this.mailInput.nativeElement.value.trim();
        const styleElement = document.querySelector('.mail-input-with-placeholder') as HTMLStyleElement;
        if (styleElement) {
          if (inputValue !== '') {
            styleElement.style.color = 'rgba(0, 0, 0, 0)';
          } else {
            styleElement.style.color = 'red';
          }
        }
      });
      this.fullnameInput?.nativeElement.addEventListener('input', () => {
        const inputValue = this.fullnameInput.nativeElement.value.trim();
        const styleElement = document.querySelector('.fullname-input-with-placeholder') as HTMLStyleElement;
        if (styleElement) {
          if (inputValue !== '') {
            styleElement.style.color = 'rgba(0, 0, 0, 0)';
          } else {
            styleElement.style.color = 'red';
          }
        }
      });
      this.urlInput?.nativeElement.addEventListener('input', () => {
        const inputValue = this.urlInput.nativeElement.value.trim();
        const styleElement = document.querySelector('.url-input-with-placeholder') as HTMLStyleElement;
        if (styleElement) {
          if (inputValue !== '') {
            styleElement.style.color = 'rgba(0, 0, 0, 0)';
          } else {
            styleElement.style.color = 'red';
          }
        }
      });

      this.usernameInput?.nativeElement.addEventListener('input', () => {
        const inputValue = this.usernameInput.nativeElement.value.trim();
        const styleElement = document.querySelector('.username-input-with-placeholder') as HTMLStyleElement;
        if (styleElement) {
          if (inputValue !== '') {
            styleElement.style.color = 'rgba(0, 0, 0, 0)';
          } else {
            styleElement.style.color = 'red';
          }
        }
      });
      this.addSitePassInput?.nativeElement.addEventListener('input', () => {
        const inputValue = this.addSitePassInput.nativeElement.value.trim();
        const styleElement = document.querySelector('.addSitePass-input-with-placeholder') as HTMLStyleElement;
        if (styleElement) {
          if (inputValue !== '') {
            styleElement.style.color = 'rgba(0, 0, 0, 0)';
          } else {
            styleElement.style.color = 'red';
          }
        }
      });

      this.projectInput?.nativeElement.addEventListener('input', () => {
        const inputValue = this.projectInput.nativeElement.value.trim();
        const styleElement = document.querySelector('.project-input-with-placeholder') as HTMLStyleElement;
        if (styleElement) {
          if (inputValue !== '') {
            styleElement.style.color = 'rgba(0, 0, 0, 0)';
          } else {
            styleElement.style.color = 'red';
          }
        }
      });
      this.passwordInput?.nativeElement.addEventListener('input', () => {
        const inputValue = this.passwordInput.nativeElement.value.trim();
        const styleElement = document.querySelector('.password-input-with-placeholder') as HTMLStyleElement;
        if (styleElement) {
          if (inputValue !== '') {
            styleElement.style.color = 'rgba(0, 0, 0, 0)';
          } else {
            styleElement.style.color = 'red';
          }
        }
      });
    }
    catch (error) {
      console.error('An error occurred:', error);

    }
  }

  buildForm() {
    this.addSiteForm = new FormGroup({
      username: new FormControl('', Validators.required),
      url: new FormControl('', [Validators.required, Validators.pattern("^\\S*$")]),
      userPassword: new FormControl(),
      password: new FormControl(),
      comments: new FormControl(),
      email: new FormControl('', [Validators.email]),
      name: new FormControl(),
      project: new FormControl(),
      username1: new FormControl(),
      url1: new FormControl(),
      comments1: new FormControl(),
      username2: new FormControl(),
      url2: new FormControl(),
      comments2: new FormControl()
    });


  }

  get f() {
    return this.addSiteForm.controls;
  }
  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  onSubmit(siteData: any) {
    this.submitted = true;
    this.enableSubmit = false;
    this.isThink = false
    if (this.changedUser) {
      Swal.fire("", "מאתחל נתונים לאחר שינוי שם משתמש, לחצו על כפתור 'שלח' שוב", 'warning')
      this.initPage({ username: siteData.username });
      this.changedUser = false
      this.enableSubmit = true;
      this.isThink = true
      return;
    }
    else if (this.addSiteForm.invalid) {
      this.enableSubmit = true;
      this.isThink = true
      return;
    }
    else if (this.userData.email.replace(/,/g, '').length == 0 && !this.displayEmailDB) {
      this.enableSubmit = true;
      this.isThink = true
      this.displayEmailDB = true;
      Swal.fire("", this.emailDbAlert, 'warning')
      return;
    }
    else if ((this.userData.isOrgServer == 1 || this.isEltaServer) && (siteData.email == null || siteData.email == '')) {
      this.enableSubmit = true;
      this.isThink = true
      this.addSiteForm.get('email').setValidators(Validators.required)
      this.addSiteForm.get('email').setErrors({ required: 'no email' })
      return
    }
    else {
      if (this.passRequired) {
        this.validPass = siteData["password"] == this.userData.AddSitesCode
      }
      if (this.validPass) {
        siteData.comments = siteData.comments ? siteData.comments.replace(/&/g, ' and ') : ""
        siteData.comments1 = siteData.comments1 ? siteData.comments1.replace(/&/g, ' and ') : ""
        siteData.comments2 = siteData.comments2 ? siteData.comments2.replace(/&/g, ' and ') : ""
        const moreComments = this.matrixUser ? (" " + siteData.email + " " + siteData.name + " " + siteData.project) : ""
        if (siteData.url.indexOf("stick.enativ.com") > -1) {//if (siteData.url.includes("stick.enativ.com")) {
          siteData.url = this.getParameterByName('url', siteData.url)
        }
        siteData["sites"] = [{ url: siteData.url, comments: siteData.comments + moreComments }]
        if (siteData.url1 != null && siteData.url1.trim().length > 4) {
          if (siteData.url1.indexOf("stick.enativ.com") > -1) {// if (siteData.url1.includes("stick.enativ.com")) {
            siteData.url1 = this.getParameterByName('url', siteData.url1)
          }
          siteData["sites"].push({ url: siteData.url1, comments: siteData.comments1 })
        }
        if (siteData.url2 != null && siteData.url2.trim().length > 4) {
          if (siteData.url2.indexOf("stick.enativ.com") > -1) {//if (siteData.url2.includes("stick.enativ.com")) {
            siteData.url2 = this.getParameterByName('url', siteData.url2)
          }
          siteData["sites"].push({ url: siteData.url2, comments: siteData.comments2 })
        }
        siteData["email"] = siteData["email"] == null ? "" : siteData["email"];
        siteData["sites"] = JSON.stringify(siteData["sites"]);

        if (this.displayEmailDB) {
          if (siteData["email"] !== "") {
            this.apiService.updateUserEmail(siteData["email"]).subscribe(
              data => {
                if (data["d"]) {
                  const storageData = JSON.parse(localStorage.getItem('nativUser'))
                  storageData.email = siteData["email"]
                  localStorage.setItem('nativUser', JSON.stringify(storageData));
                  this.addSite(siteData);
                }
              },
              error => {
                console.log('error updateUserEmail', error)
              }
            )
          }
          else {
            this.addSite(siteData);
          }
        }
        else {
          this.addSite(siteData);
        }
      }
      else {
        this.enableSubmit = true;
        this.isThink = true
        // this.addSiteForm.get('password').setErrors('invalid password')
        return
      }
    }
  }

  changeUser() {
    this.changedUser = true
    this.addSiteForm.get('username').reset()
    this.addSiteForm.get('userPassword').reset()
    this.addSiteForm.get('email').reset();
    this.disabledUsername = false;
    this.showChangeUser = true;
    this.addSiteForm.get('userPassword').setValidators(Validators.required)
    this.addSiteForm.get('email').setValidators([Validators.email])
    this.matrixUser = false;
    this.passRequired = false;
    this.gviaproblem = false;
    this.overQuota = false;

    this.addSiteForm.get('name').clearValidators();
    this.addSiteForm.get('project').clearValidators();
    this.addSiteForm.get('password').clearValidators();
    this.addSiteForm.get('name').setErrors(null);
    this.addSiteForm.get('project').setErrors(null);
    this.addSiteForm.get('password').setErrors(null);
  }
  checkValue(e) {
    if (e.target.checked) {
      this.enableSubmit = true;
    }
    else {
      this.enableSubmit = false;
    }
  }

  addSite(siteData) {
    console.log('add site has been submitted', siteData);
    try {
      const windowsUsers = this.userDataAuth.WindowsUsers ? (Object.keys(this.userDataAuth.WindowsUsers)[0] + "-" + Object.values(this.userDataAuth.WindowsUsers)[0]) : ""
      const windowsMachine = this.userDataAuth.WindowsMachine ? this.userDataAuth.WindowsMachine : ""
      const nativVersion = this.userDataAuth.NativVersion ? this.userDataAuth.NativVersion : ""
      const reqIp = this.reqIp
      const reqData = JSON.stringify({
        cats: this.reqCats, dec: this.reqDec, sid: this.reqSid, ref: this.reqRef,
        windowsMachine, windowsUsers: encodeURI(windowsUsers), nativVersion, reqIp
      })
      this.apiService.addSite(siteData, reqData).subscribe(
        data => {
          console.log('success send addSite', data)
          this.enableSubmit = true;
          this.isThink = true
          if (data["d"]) {
            if (data["d"] == -9) {
              Swal.fire("", "קיימת כבר בקשה לאתר זה", 'warning')
              console.log('error addSite', data)
            }
            else if (data["d"] == -99) {
              Swal.fire("", "אירעה שגיאה בשליחת הבקשה", 'warning')
              console.log('error addSite', data)
            }
            else if (data["d"] == -98) {
              Swal.fire("", "עקב בעיה בגביה הנך מנוע מלהוסיף אתרים! אנא פנה להנהלת חשבונות להמשך בירור", 'warning')
              console.log('error addSite', data)
            }
            else {
              Swal.fire("", "הבקשה נשלחה", 'success')
              if (this.userDataAuth.id == environment.iaiServerUserId || this.userDataAuth.id == environment.eltaServer)
                this.router.navigate(['/main'])
              else
                this.router.navigate(['/my-sites'])
            }
          }
          else {
            Swal.fire("", "אירעה שגיאה בשליחת הבקשה: " + "no response data: " + data, 'error')
            console.log('error addSite', data)
          }
        },
        error => {
          this.enableSubmit = true;
          this.isThink = true
          Swal.fire("", "אירעה שגיאה בשליחת הבקשה: " + error.message, 'error')
          console.log('error addSite', error)
        }
      )
    }
    catch (error) {
      console.log(error)
      this.enableSubmit = true;
      this.isThink = true
      Swal.fire("", "אירעה שגיאה בשליחת הבקשה: " + error.message, 'error')
    }
  }

  navigateToAdvSite(site, location) {
    if (site == "asraf") {
      const to = "0587804574a@gmail.com"
      const subject = "הי ישראל הגעתי מנתיב גם אני רוצה לקבל בדיקה חינם ולחסוך אלפי שקלים בחודש"
      const body = encodeURI("נא למלא פרטים\nשם:\nטלפון:")
      window.open(`https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${to}&su=${subject}&body=${body}&tf=1`, '_blank');
    }
    else if (site == "news-hot") {
      window.open("https://achadashhot.com/", '_blank');
    }
    else if (site == "news-hot-mail") {
      site = "news-hot"
      const to = "Hachadashhot@gmail.com"
      const subject = "גם אני רוצה להתעדכן בחדשות מבית חדשHOT"
      window.open(`https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${to}&su=${subject}&body=&tf=1`, '_blank');
    }
    else if (site == "infinity-ground") {
      window.open("https://www.lighter-life.co.il/?utm_source=nativ", '_blank');
    }
    else if (site == "elisheva-mail") {
      site = 'bool-ey'
      const to = "elishevadout@gmail.com"
      const subject = "הגעתי מאתר נתיב אשמח לקבל קטלוג למייל"
      window.open(`https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${to}&su=${subject}&body=&tf=1`, '_blank');
    }
    else if (site == "elisheva-site") {
      site = 'bool-ey'
      window.open("https://bool-ey.studio/gallery/", '_blank');
    }
    else if (site == "besadno") {
      window.open("https://bsdnosk.co.il/podcast/?utm_source=netiv&utm_medium=baner", '_blank');
    }
    else if (site == "shtiglitz-group") {
      window.open("https://lp.shtiglitz.co.il/smart-j/?utm_source=netiv&utm_medium=baner", '_blank');
    }
    else if (site == "tzameret") {
      window.open("https://tsameret.co.il/adhd/", '_blank');
    }
    else if (site == "artex-home") {
      window.open("https://rtex.co.il/", '_blank');
    }
    else if (site == "ezer-mitzion") {
      window.open("https://www.ami.org.il/donation-campaign/?utm_source=whatsappharedi&utm_medium=nativ&utm_campaign=7-9&utm_content=5", "_blank")
    }
    else {
      window.open(`https://enativ.com`, '_blank');
    }
    this.apiService.saveAdvActions({ action: 'click', adv: site, page: 'add-site', bunnerLocation: location, sourceSite: 'nativ-site' }).subscribe(
      data => {
        console.log(`success saveAdvActions ${data}`)
      },
      error => {
        console.log(`error saveAdvActions ${error}`)
      }
    )
  }

  openAdv(advNum) {
    const time = 1000 * 5
    const that = this
    setTimeout(() => {
      that.advsData[advNum].show = true;
    }, time);
  }

  closeAdv(advNum) {
    const time = 1000 * 60 * 5
    this.advsData[advNum].show = false;
    const that = this
    setTimeout(() => {
      that.advsData[advNum].show = true;
    }, time);
  }

  checkShowAdvs() {
    if (this.authenticationService.userData?.usertypeid) {
      const userData = this.authenticationService.userData
      const userType = this.utilsService.getUserType(userData.usertypeid)
      if (userType.IS_USER_MAIL_ONLY || (userData.usertypeid.substring(userData.usertypeid.length - 2, userData.usertypeid.length - 1) == "4")) {
        this.showAdvs = false
      }
      // else {
      //   this.showAdvs = true
      // }
    }
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }
}
